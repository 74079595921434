/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cognito_identity_pool_id": "ca-central-1:ec9a3dc9-d16c-4987-b855-c153e70fec1e",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_aL3eUb55B",
    "aws_user_pools_web_client_id": "2agjfvfo9n9vpshog0vr5gnbli",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "msea-sensor-data-raw90339-dev112805-dev",
    "aws_user_files_s3_bucket_region": "ca-central-1"
};


export default awsmobile;
